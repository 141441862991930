<template>
  <div class="">
    <AdminForm ref="AdminForm" v-model="formData" @formSubmitted="storeAdmin"/>
  </div>
</template>

<script>
import AdminForm from "@/views/components/admin-manangement/AdminForm";
import AdminService from "@/services/AdminService";

export default {
  name: "CreateAdmin",
  components: {
    AdminForm
  },
  data() {
    return {
      adminService: new AdminService(),
      formData: {
        // what key i have
      },
    }
  },
  methods: {
    storeAdmin(e) {
      this.adminService.create(e).then(res => {
        this.$router.push('/admin-management')
        // window.location.href = 'home'
      }).catch(err => {
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>
</style>
